import React from 'react';

interface Props {}

export const Experience = (props: Props) => {
  return (
    <div className='main-content-wrapper'>
      <h1>exp</h1>
    </div>
  );
};
